import React from 'react';
import { graphql } from 'gatsby';
import { PlatformDetail } from '../model/types/platformDetailTypes';
import PlatformDetailComponent from '../components/platformDetail/PlatformDetail';
import { PlatformDetailQuery } from '../graphql';
import { sortAndFilterPlatformData } from './utility/sortAndFilterPlatformDataUtilities';

type Props = {
    data: PlatformDetailQuery;
};

const PlatformDetailDataProvider = ({ data }: Props) => {
    const details = data.contentfulPlatform as PlatformDetail;

    const sortedContentDetails = sortAndFilterPlatformData(details);

    return (
        <PlatformDetailComponent
            details={details}
            sortedContentDetails={sortedContentDetails}
        />
    );
};

export default PlatformDetailDataProvider;

// The $locale and $slug parameters come from the routing context. It is added in gatsby-node.js as part of a dynamic route
export const query = graphql`
    query PlatformDetail($slug: String!) {
        contentfulPlatform(slug: { eq: $slug }) {
            id
            title
            slug
            logo {
                title
                resize(height: 110) {
                    src
                }
            }
            websiteUrl
            organizationType
            description {
                json
            }
            foundedAt(formatString: "DD-MM-YYYY")
            addressHeadquarters {
                streetName
                number
                addition
                postcode
                city
                country
            }
            sector {
                title
                parentSector {
                    title
                }
            }
            serviceType
            totalEmployees
            verified
            serviceType
            activeCountriesList
            totalEmployees
            acceleratePayment
            algorithmActivities
            algorithmExplanation
            businessModel
            checks
            clientRatingSystem
            clientReviews
            clientType
            commissionClient
            commissionPlatform
            consequences
            firstContact
            insurance
            guaranteedPayment
            jobInterview
            legalForm
            matchingAlgorithm
            matchingExplanation
            matchingProcess {
                id
                matchingProcess
            }
            otherChecks
            paperCheck
            priceControl
            pricing
            profileInformation
            providerReviews
            ratingSystem
            scoreAdjustmentClient
            scoreAdjustmentConflict
            scoreCalculation
            scoreLayering
            timeUntilPayment
            termsAndConditions
        }
    }
`;
