import React from 'react';
import useToggleVisibility from '../../../hooks/useToggleVisibility';
import Heading, { TagOptions, WeightOptions } from '../heading/Heading';
import styles from './toggleBlock.module.scss';
import { ReactComponent as ToggleArrow } from '../../../images/icons/arrow-down.svg';
import createClassName from 'classnames';

type Props = {
    title: string;
    children: React.ReactNode;
    initialState?: boolean;
};

const ToggleBlock = ({ title, children, initialState = false }: Props) => {
    const { isVisible, toggleVisibility } = useToggleVisibility(initialState);

    return (
        <div className={styles.toggleBlock}>
            <Heading
                tag={TagOptions.h3}
                weight={WeightOptions.extraBold}
                flatten
            >
                <button
                    onClick={toggleVisibility}
                    aria-expanded={isVisible}
                    className={styles.headingToggleButton}
                >
                    <span>{title}</span>
                    <ToggleArrow className={styles.headingToggleIcon} />
                </button>
            </Heading>
            <div
                className={createClassName(styles.expandedContent, 'wysiwyg')}
                hidden={!isVisible}
            >
                {children}
            </div>
        </div>
    );
};

export default ToggleBlock;
