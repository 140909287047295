import React, { Fragment } from 'react';
import { PlatformDetailSector } from '../../../model/types/platformDetailTypes';
import styles from '../platformDetail.module.scss';
import { selectNestedSectorTitles } from '../utility/sectorSelectors';

type Props = {
    sectors: PlatformDetailSector[];
};

const PlatformSectorList: React.FC<Props> = ({ sectors }) => {
    const nestedSectorsTitles = selectNestedSectorTitles(sectors);

    return (
        <>
            {Object.keys(nestedSectorsTitles).map((parentSector) => (
                <Fragment key={parentSector}>
                    <span className={styles.parentSector}>{parentSector}</span>
                    {nestedSectorsTitles[parentSector].join(', ')}
                </Fragment>
            ))}
        </>
    );
};

export default PlatformSectorList;
