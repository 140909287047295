import { PlatformDetailSector } from '../../../model/types/platformDetailTypes';

type GroupedSectorsType = {
    [key: string]: string[];
};

export function selectNestedSectorTitles(sectors: PlatformDetailSector[]) {
    return sectors.reduce<GroupedSectorsType>((groupedSectors, sector) => {
        if (typeof sector === 'undefined') {
            return groupedSectors;
        }

        const parentGroup = (sector.parentSector || [])[0];

        if (!parentGroup) {
            return groupedSectors;
        }

        const { title } = parentGroup;

        if (typeof groupedSectors[title] === 'undefined') {
            groupedSectors[title] = [];
        }

        groupedSectors[title].push(sector.title);

        return groupedSectors;
    }, {});
}
