import { PlatformDetail } from '../../model/types/platformDetailTypes';

export enum SortedPlatformDetailContentKey {
    selectionProcessDetails = 'selectionProcessDetails',
    providerInformationDetails = 'providerInformationDetails',
    compensationDetails = 'compensationDetails',
    matchingDetails = 'matchingDetails',
    algorithmDetails = 'algorithmDetails',
    ratingSystemDetails = 'ratingSystemDetails',
    activeCountryDetails = 'activeCountryDetails',
}

export type SortedPlatformDetailContent = {
    [key in SortedPlatformDetailContentKey]?: string[];
};

export const sortAndFilterPlatformData = (
    details: PlatformDetail
): SortedPlatformDetailContent => {
    const sortedContentDetails = {
        selectionProcessDetails: [
            details.jobInterview,
            details.checks,
            details.otherChecks,
            details.paperCheck,
            details.firstContact,
        ],
        providerInformationDetails: [
            details.profileInformation,
            details.legalForm,
            details.insurance,
        ],
        compensationDetails: [
            details.pricing,
            details.priceControl,
            details.commissionPlatform,
            details.commissionClient,
            details.timeUntilPayment,
            details.acceleratePayment,
            details.guaranteedPayment,
        ],
        matchingDetails: [
            details.matchingProcess?.matchingProcess,
            details.matchingExplanation,
            details.matchingAlgorithm,
            details.algorithmExplanation,
        ],
        algorithmDetails: details.algorithmActivities
            ? details.algorithmActivities
            : [],
        ratingSystemDetails: [
            details.ratingSystem,
            details.clientReviews,
            details.scoreCalculation,
            details.scoreLayering,
            details.scoreAdjustmentConflict,
            details.scoreAdjustmentClient,
            details.consequences,
            details.clientRatingSystem,
            details.providerReviews,
        ],
        activeCountryDetails: [details.activeCountriesList],
    };

    const prepared: SortedPlatformDetailContent = {};

    const allKeys = Object.keys(
        sortedContentDetails
    ) as SortedPlatformDetailContentKey[];

    allKeys.forEach((key) => {
        // remove any falsy items like null and undefined
        const items = (sortedContentDetails[key] || []).filter(
            (item) => !!item
        ) as string[];

        if (items.length === 0) {
            return;
        }

        prepared[key] = items;
    });

    return prepared;
};
