import React from 'react';
import { PlatformDetail } from '../../../model/types/platformDetailTypes';
import styles from '../platformDetail.module.scss';
import Heading, {
    TagOptions,
    StyleOptions,
    SizeVariationOptions,
} from '../../primitives/heading/Heading';

type Props = {
    platformDetails: PlatformDetail;
};

const CompanyDetails = ({ platformDetails }: Props) => {
    const {
        foundedAt,
        organizationType,
        addressHeadquarters,
        totalEmployees,
        serviceType,
        verified,
    } = platformDetails;

    return (
        <div className={styles.companyDetails}>
            <div>
                <Heading
                    tag={TagOptions.h6}
                    style={StyleOptions.base}
                    size={SizeVariationOptions.base}
                    flatten
                >
                    Oprichting
                </Heading>
                {foundedAt}
            </div>
            {addressHeadquarters && (
                <div>
                    <Heading
                        tag={TagOptions.h6}
                        style={StyleOptions.base}
                        size={SizeVariationOptions.base}
                        flatten
                    >
                        HQ Adres
                    </Heading>
                    {addressHeadquarters.streetName}{' '}
                    {addressHeadquarters.number}{' '}
                    {addressHeadquarters.addition
                        ? addressHeadquarters.addition
                        : null}
                    , {addressHeadquarters.postcode} {addressHeadquarters.city}
                    <br />
                    {addressHeadquarters.country}
                </div>
            )}

            <div>
                <Heading
                    tag={TagOptions.h6}
                    style={StyleOptions.base}
                    size={SizeVariationOptions.base}
                    flatten
                >
                    Soort organisatie
                </Heading>
                {organizationType}
            </div>
            <div>
                <Heading
                    tag={TagOptions.h6}
                    style={StyleOptions.base}
                    size={SizeVariationOptions.base}
                    flatten
                >
                    Aantal werknemers
                </Heading>
                {totalEmployees ? totalEmployees : 'Onbekend'}
            </div>
            <div>
                <Heading
                    tag={TagOptions.h6}
                    style={StyleOptions.base}
                    size={SizeVariationOptions.base}
                    flatten
                >
                    Type dienst
                </Heading>
                {serviceType.join(', ')}
            </div>
            <div>
                <Heading
                    tag={TagOptions.h6}
                    style={StyleOptions.base}
                    size={SizeVariationOptions.base}
                    flatten
                >
                    Geverifieerd profiel
                </Heading>
                {verified ? 'Ja' : 'Nee'}
            </div>
        </div>
    );
};

export default CompanyDetails;
