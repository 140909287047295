import MarkdownIt from 'markdown-it';
import linkAttributePlugin from 'markdown-it-link-attributes';

const markdownRenderer = new MarkdownIt({
    breaks: true,
    html: true,
    xhtmlOut: true,
    linkify: true,
});

markdownRenderer.use(linkAttributePlugin, {
    attrs: {
        target: '_blank',
        rel: 'noopener',
    },
});

export const renderMarkdown = (content: string): string =>
    markdownRenderer.render(content);
