import React from 'react';
import { PlatformDetail as PlatformDetailModel } from '../../model/types/platformDetailTypes';
import SEO from '../primitives/Seo';
import Heading, {
    TagOptions,
    WeightOptions,
} from '../primitives/heading/Heading';
import useCreateBackToOverviewPathOnFilterChange from './hooks/useCreateBackToOverviewPathOnFilterChange';
import CompanyDetails from './components/CompanyDetails';
import ContentBlock from '../primitives/contentBlock/ContentBlock';
import ContentBlockMain from '../primitives/contentBlock/components/ContentBlockMain';
import styles from './platformDetail.module.scss';
import ContentContainer from '../primitives/contentBlock/components/ContentContainer';
import PlatformSectorList from './components/PlatformSectorList';
import PlatformLinks from './components/PlatformLinks';
import ToggleBlock from '../primitives/toggleBlock/ToggleBlock';
import PageHeader from '../primitives/pageHeader/PageHeader';
import ContentFulRichText from '../../contentful/components/ContentFulRichText';
import {
    SortedPlatformDetailContent,
    SortedPlatformDetailContentKey,
} from '../../templates/utility/sortAndFilterPlatformDataUtilities';
import { renderMarkdown } from './utility/contentItemMarkdownRenderer';

const toggleBlockIdentifiers = {
    selectionProcessDetails: 'Selectieproces',
    providerInformationDetails: 'De Aanbieder',
    compensationDetails: 'Vergoeding/Fee',
    matchingDetails: 'Matching',
    algorithmDetails: 'Algoritme',
    ratingSystemDetails: 'Reputatie Systeem',
    activeCountryDetails: 'Actieve Landen',
};

type Props = {
    details: PlatformDetailModel;
    sortedContentDetails: SortedPlatformDetailContent;
};

const PlatformDetail = ({ details, sortedContentDetails }: Props) => {
    const {
        title,
        logo,
        sector: sectors,
        description,
        websiteUrl,
        termsAndConditions,
        businessModel,
    } = details;

    const { backToOverviewPath } = useCreateBackToOverviewPathOnFilterChange();

    const sortedContentDetailKeys = Object.keys(
        sortedContentDetails
    ) as SortedPlatformDetailContentKey[];

    return (
        <>
            <SEO title={`${title} - Platformen`} />
            <PageHeader backToOverviewPath={backToOverviewPath} />
            <ContentBlock>
                <ContentBlockMain>
                    {logo && logo.resize && logo.resize.src && (
                        <div className={styles.companyLogo}>
                            <img src={logo.resize.src} alt={logo.title} />
                        </div>
                    )}
                    <ContentContainer>
                        <header className={styles.header}>
                            <Heading
                                tag={TagOptions.h1}
                                weight={WeightOptions.extraBold}
                                flatten
                            >
                                {title}
                            </Heading>
                            {Array.isArray(sectors) && (
                                <div className={styles.headerSectors}>
                                    <PlatformSectorList sectors={sectors} />
                                </div>
                            )}
                        </header>
                        <ContentFulRichText content={description} />
                        <PlatformLinks
                            websiteUrl={websiteUrl}
                            termsAndConditions={termsAndConditions}
                        />
                        <CompanyDetails platformDetails={details} />
                        {businessModel && (
                            <div className={styles.businessmodelBlock}>
                                <Heading
                                    tag={TagOptions.h2}
                                    weight={WeightOptions.extraBold}
                                >
                                    Business Model
                                </Heading>
                                <div className="wysiwyg">{businessModel}</div>
                            </div>
                        )}
                        {sortedContentDetailKeys.map((property, index) => (
                            <ToggleBlock
                                key={index}
                                title={toggleBlockIdentifiers[property]}
                            >
                                {property ===
                                    SortedPlatformDetailContentKey.algorithmDetails && (
                                    <p>
                                        Het platform heeft de onderstaande
                                        activiteiten geautomatiseerd door middel
                                        van een algoritme:
                                    </p>
                                )}
                                <ul>
                                    {(sortedContentDetails[property] || []).map(
                                        (item, index) => (
                                            <li
                                                key={index}
                                                dangerouslySetInnerHTML={{
                                                    __html: renderMarkdown(
                                                        item
                                                    ),
                                                }}
                                            ></li>
                                        )
                                    )}
                                </ul>
                            </ToggleBlock>
                        ))}
                    </ContentContainer>
                </ContentBlockMain>
            </ContentBlock>
        </>
    );
};

export default PlatformDetail;
