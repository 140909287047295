import React from 'react';
import parse from 'url-parse';
import { ReactComponent as ExternalLinkIcon } from '../../../images/icons/external-link.svg';
import styles from '../platformDetail.module.scss';

type Props = {
    websiteUrl: string;
    termsAndConditions?: string | null;
};

const PlatformLinks = ({ websiteUrl, termsAndConditions }: Props) => {
    const websiteHostName = parse(websiteUrl).hostname;

    return (
        <div className={styles.platformLinks}>
            <a
                href={websiteUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.platformLink}
            >
                <ExternalLinkIcon className={styles.platformLinkIcon} />
                {websiteHostName}
            </a>
            {termsAndConditions && (
                <a
                    href={termsAndConditions}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.platformLink}
                >
                    Algemene voorwaarden
                </a>
            )}
        </div>
    );
};

export default PlatformLinks;
